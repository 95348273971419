$(".clear-advanced-search").click(() => {
  $(".advanced-search-form").each(function () {
    var attr_ids = ["#issuance_number", "#name"];

    $.each(attr_ids, function (_, attr_id) {
      $(".advanced-search-form").find(attr_id).val("");
    });

    $(this).find(".selectized").each((_, select) => {
      $selectize = $(select)[0].selectize
      $selectize.clear(true)
    })

    $(this).find(".default-flatpickr").each((_, flatpickr_input) => {
      $flatpickr = flatpickr_input.flatpickr()
      $flatpickr.clear()
    })
  })
});
