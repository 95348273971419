require('selectize/dist/js/selectize.min')
require('selectize/dist/css/selectize.bootstrap3.css')

$(document).ready(function() {
  var default_single_selectize = $(".default-single-selectize")
  var default_multiple_selectize = $(".default-multiple-selectize")
  var section_ids_selectize = $(".section-ids-selectize")

  var default_selectize_opts = {
    plugins: ['restore_on_backspace', 'remove_button'],
    searchField: ['text', 'optgroup'],
    valueField: "id",
    allowEmptyOption: true,
    showEmptyOptionInDropdown: true,
    emptyOptionLabel: true,
    hideSelected: true,
    placeholder: "Please Select"
  }

  default_single_selectize.selectize(default_selectize_opts)
  default_multiple_selectize.selectize(default_selectize_opts)

  var library_ids_selectize = $(".library-ids-selectize")

  default_single_selectize.each(function(_, $el) {
    $single_selectize = $($el)[0].selectize
    if ($single_selectize.getValue() === '') {
      $single_selectize.clear()
    }
  })

  function sectionsAjax(q, product_ids, callback) {
    $.ajax({
      url: '/sections.json',
      type: 'GET',
      dataType: 'json',
      data: {
        q: q,
        library_ids: product_ids,
        child_only: true,
        per_page: 1000000
      },
      error: function() {
        callback()
      },
      success: function(results) {
        callback(results)
      }
    });
  }

  var section_ids_opts = {
    allowEmptyOption: false,
    showEmptyOptionInDropdown: false,
    optgroupField: 'parent_name',
    optgroupLabelField: 'parent_name',
    optgroupValueField: 'parent_id',
    loadThrottle: 3000,
    preload: true
  }

  library_ids_selectize.selectize(default_selectize_opts)
});
