$(".clickable-td").click(function() {
  if($(this).attr("href") != undefined) {
    window.open($(this).attr("href"))
  }
});

if ($(".attachments_attributes").length) {
  $("body").delegate(".add-attachments-attributes-button", "click", function () {
    var count = $(".attachments_attributes tr").length - 1
    var doc_attr = "name='elog_document[attachments_attributes][" + count + "][doc]' id='elog_document_attachments_attributes_" + count + "_doc'";
    var source_attr = "name='elog_document[attachments_attributes][" + count + "][source]' id='elog_document_attachments_attributes_" + count + "_source'";
    var accepted_file_types = "application/pdf, image/jpg, image/jpeg, image/png";
    var add_button = "<button type='button' class='btn btn-sm btn-primary pull-right add-attachments-attributes-button'>" +
      "<i class='fa fa-plus' data-toggle='tooltip' data-placement='bottom' title='Add new attachment'> </i>" +
      "</button>";

    var source_id = "elog_document_attachments_attributes_" + count + "_source"
    $(".add-attachments-attributes-button").remove();
    $(".attachments_attributes").append("<tr> <td class='form-group default-attachment-attributes-table-width'> <input accept='"+ accepted_file_types +"' class='form-control' type='file' "+ doc_attr +"></td>" +
      "<td class='form-group attachment-source-table-width'> <select prompt='Please Select' class='form-control' "+ source_attr +"></td>" +
      "<td class='action-column'>" + add_button + "</td></tr>");

    $.each($(".attachments_attributes tbody tr"), function(index) {
      if ($(this).has("button.delete-attachments-attributes-button").length === 0 && $(this).has("button.add-attachments-attributes-button").length === 0) {
        $(this).find(".action-column").append("<button type='button' class='btn btn-sm btn-danger pull-right delete-attachments-attributes-button'>" +
          "  <i class='fa fa-trash-o' data-toggle='tooltip' data-placement='bottom' title='Delete attachment'></i>" +
          "</button>");
      }
    });

    $.ajax({
      url: "/elog/attachments/sources.json",
      success:
        function(sources) {
          $("#" + source_id + "").append(new Option("Unknown", "Unknown"))
          $.each(sources, function(key, value) {
            $("#" + source_id + "").append(new Option(value, value))
          });
        }
    });
  });

  $("body").delegate(".delete-attachments-attributes-button", "click", function () {
    $(this).parents().closest("tr").remove()

    $.each($(".attachments_attributes tbody tr"), function(index) {
      var doc_attr_name = "name='elog_document[attachments_attributes][" + index + "][doc]'";
      var doc_attr_id = "id='elog_document_attachments_attributes_" + index + "_doc'";
      var source_attr_name = "name='elog_document[attachments_attributes][" + index + "][source]'";
      var source_attr_id = "id='elog_document_attachments_attributes_" + index + "_source'";

      $.each($(this).children(), function() {
        var attachments_attributes_select = $(this).find("select")
        var attachments_attributes_input = $(this).find("input")
        if (attachments_attributes_input.length && attachments_attributes_input.attr("id").includes("doc")) {
          attachments_attributes_input.prop("name", doc_attr_name)
          attachments_attributes_input.prop("id", doc_attr_id)
        } else if (attachments_attributes_select.length && attachments_attributes_select.attr("id").includes("source")) {
          attachments_attributes_select.prop("id", source_attr_id)
          attachments_attributes_select.prop("name", source_attr_name)
        }
      });
    });
  });
}
